<template>
  <div class="column m-t-2 step-fields">
    <div class="ui step-wrap">
      <div class="step-left">
        <Ingredients
          :group="this.group"
          :step="this.step"
          :step-endpoint="stepEndpoint"
        />
      </div>

      <div class="step-right">
        <div class="ui form">
          <div class="field">
            <textarea
               v-model.lazy="description"
               class="maxLines"
               :placeholder="capitalize($i18n.t('activerecord.attributes.step.description'))"
            />
          </div>

          <IcookinFields :step="step" :step-endpoint="stepEndpoint" v-if="isIcookable" />
          <Accessories :step="step" :step-endpoint="stepEndpoint" v-if="isIcookable" />

        </div>
      </div>

      <div class="step-remove">
        <a
          class="custom-link--remove"
          @click="confirmDeletion"
        >
          <i class="gdm-supprimer icon"></i>
          <span>{{ capitalize($i18n.t('elements.recipe.buttons.del_step')) }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { inflection, api } from './mixins'

import Ingredients from './Ingredients.vue'
import IcookinFields from './IcookinFields.vue'
import Accessories from './Accessories.vue'


export default {
  props: ['group', 'step'],
  mixins: [inflection, api],
  components: { Ingredients, IcookinFields, Accessories },
  data () {
    return {
      description: this.step.description
    }
  },
  watch: {
    description () {
      this.sendRequest('put', this.stepEndpoint, { description: this.description })
      .then( (response) => {
        this.description = response.data.description
      })
    }
  },
  computed: {
    ...mapGetters(['isIcookable']),
    stepEndpoint () {
      return `${this.recipePath}/groups/${this.group.id}/steps/${this.step.id}`
    }
  },
  methods: {
    confirmDeletion () {
      this.$store.dispatch('openDeleteConfirmation', {
        resource: 'step',
        path: this.stepEndpoint
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
